<section class="next this-page-1">
  <h3>Categories</h3>
  <div class="container" style="margin-bottom: 3rem;">
    <a routerLink="/works" class="category-list">Featured</a>
    <a *ngFor="let category of workCategories" routerLink="/works/{{category._id}}"
      class="category-list">{{category.title}}</a>
  </div>


  <h3>{{pageTitle}}</h3>
  <div class="container">

    <div class="row">

      <div *ngFor="let work of groupOne" class="col-md-4">
        <div class="card mb-2">
          <a routerLink="/works/view/{{work._id}}">
            <img class="card-img-top shadow" src="{{backend.uploadsUrlPrefix}}/small/{{work.imageSortHash}}.jpg"
              alt="{{work.title}}"></a>
          <div class="card-body">
            <h4 class="card-title"><a routerLink="/works/view/{{work._id}}">{{work.title}}</a></h4>
            <p class="card-text">{{work.desc}}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div *ngFor="let work of groupTwo" class="col-md-4">
        <div class="card mb-2">
          <a routerLink="/works/view/{{work._id}}">
            <img class="card-img-top shadow" src="{{backend.uploadsUrlPrefix}}/small/{{work.imageSortHash}}.jpg"
              alt="{{work.title}}"></a>
          <div class="card-body">
            <h4 class="card-title"><a routerLink="/works/view/{{work._id}}">{{work.title}}</a></h4>
            <p class="card-text">{{work.desc}}</p>
          </div>
        </div>
      </div>

    </div>

    <app-pagination [pageUrl]="backendUrl" (pagedData)="receivePageContent($event)"></app-pagination>

  </div>
</section>