<!-- Bootstrap alerts for adding comments -->
<div class="alert alert-success text-center" role="alert" (click)="resetAlert()" *ngIf="successCreatingBlog">
    <a class="close" aria-label="close">&times;</a>
    <b>Comment added successfully! </b>
</div>
<div class="alert alert-danger text-center" role="alert" (click)="resetAlert()" *ngIf="failedCreatingBlog">
    <a class="close" aria-label="close">&times;</a>
    A <b>problem</b> occured while adding your comment!
</div>

<section class="next this-page-1 blog_area single-post-area area-padding">
    <div class="container">
        <h1>{{blog.title}}</h1>
        <div class="row">
            <div class="col-lg-8 posts-list">
                <div class="single-post">
                    <div class="feature-img">
                        <img class="img-fluid" src="{{backend.uploadsUrlPrefix}}/big/{{blog.imageSortHash}}.jpg" alt="">
                    </div>
                    <div class="blog_details">
                        <h2>{{blog.desc}}</h2>
                        <ul class="blog-info-link mt-3 mb-4">
                            <li><a><i class="fa fa-user"></i>{{blog.authorEmail}}</a></li>
                            <li><a routerLink="/blog"><i
                                        class="fa fa-comments"></i>{{blog.commentCount !== 0 ? blog.commentCount + ' comments' : 'No Comments'}}</a>
                            </li>
                            <li><a><i
                                        class="fa fa-comments"></i>{{ stringAsDate(blog.createdOn) | date: 'MMM d y, h:MM a' }}</a>
                            </li>
                            <!-- TDOD show confirmation alert after Delete is pressed -->
                            <li *ngIf="canEdit()"><a (click)="editBlog()" class="cursor"><i class="fa fa-edit"></i>Edit
                                    Blog</a></li>
                            <li *ngIf="canEdit()"><a (click)="deleteBlog()" class="cursor">
                                    <i class="fa fa-trash"></i>Delete Blog</a></li>
                        </ul>

                        <app-youtube [url]="youtubeUrl"></app-youtube>

                        <p id="post" [innerHTML]="blog.post | keepHtml">
                        </p>
                    </div>
                </div>

                <div class="blog-author">
                    <div class="media align-items-center">
                        <img src="{{thumbnailPicture}}" alt="">
                        <div class="media-body">
                            <a routerLink="/about">
                                <!-- TODO -->
                                <!-- <h4>{{blog.authorEmail}}</h4> -->
                                <h4>{{name}}</h4>
                            </a>
                            <p>{{desc}}</p>
                        </div>
                    </div>
                </div>

                <div class="comments-area">
                    <h4>Comments</h4>
                    <div *ngFor="let comment of blogComments; index as i" class="comment-list">
                        <div class="single-comment justify-content-between d-flex">
                            <div class="user justify-content-between d-flex">
                                <div class="thumb">
                                    <img src="/assets/img/avatar{{1 + (i % 5)}}.svg" alt="">
                                </div>
                                <div class="desc">
                                    <p class="comment">{{comment.comment}}
                                    </p>
                                    <div class="d-flex justify-content-between">
                                        <div class="align-items-center">
                                            <h5>
                                                <a href="#">{{comment.name}}</a>
                                            </h5>
                                            <p class="date">
                                                {{ stringAsDate(comment.createdOn) | date: 'MMM d y, h:MM a' }}</p>
                                            <!-- Date format December 4, 2020 at 3:12 pm -->
                                        </div>
                                        <div *ngIf="canEdit()" (click)="deleteComment(i)"><i class="fa fa-trash"></i>
                                        </div>
                                        <div class="reply-btn">
                                            <!-- <a href="#" class="btn-reply text-uppercase">reply</a> -->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="comment-form">
                    <h4>Leave a Reply</h4>
                    <form [formGroup]="commentForm" (ngSubmit)="addComment()" class="form-contact comment_form"
                        action="#" id="commentForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control w-100" maxlength="1000" formControlName="comment"
                                        name="comment" id="comment" cols="30" rows="9"
                                        placeholder="Write Comment"></textarea>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" formControlName="name" name="name" id="name" type="text"
                                        placeholder="Name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" formControlName="email" name="email" id="email"
                                        type="email" placeholder="Email (will be hidden)">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">Post Reply</button>
                        </div>
                        <!-- TODO add CAPTCHA -->
                        <app-scroll-to-top></app-scroll-to-top>
                    </form>
                </div>
            </div>

            <div class="col-lg-4">
                <app-side-bar></app-side-bar>
            </div>

        </div>
    </div>
</section>