<section>
  <div id="theCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#theCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#theCarousel" data-slide-to="1"></li>
      <li data-target="#theCarousel" data-slide-to="2"></li>
      <li data-target="#theCarousel" data-slide-to="3"></li>
      <li data-target="#theCarousel" data-slide-to="4"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <div class="carousel-caption">
        <h3 class="h3-responsive">{{name}}</h3>
        <!-- <h5>{{occupation}}</h5> -->
        <!-- <p>{{desc}}</p> -->
        <a class="btn btn-primary" style="font-family: Arial, Helvetica, sans-serif;"
           href="{{cvUrlPrefix + '/' + settings.siteSettings.curriculumVitae}}/cv.pdf">
          Download Cv
        </a>
      </div>

      <div class="carousel-item active">
        <div class="view">
          <img class="d-block w-100"
               src="{{imagesUrlPrefix + '/big/' + settings.siteSettings.landingImageOne}}" />
          <div class="mask rgba-black-light"></div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100"
             src="{{imagesUrlPrefix + '/big/' + settings.siteSettings.landingImageTwo}}" />
      </div>
      <div class="carousel-item">
        <img class="d-block w-100"
             src="{{imagesUrlPrefix + '/big/' + settings.siteSettings.landingImageThree}}" />
      </div>
      <div class="carousel-item">
        <img class="d-block w-100"
             src="{{imagesUrlPrefix + '/big/' + settings.siteSettings.landingImageFour}}" />
      </div>
      <div class="carousel-item">
        <img class="d-block w-100"
             src="{{imagesUrlPrefix + '/big/' + settings.siteSettings.landingImageFive}}" />
      </div>

    </div>
    <a class="left carousel-control" href="#theCarousel" role="button" data-slide="prev">
      <span class="icon-prev" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#theCarousel" role="button" data-slide="next">
      <span class="icon-prev" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</section>

<section class="next this-page-1 landing-message">
  <h3>{{landingMessageHeading}}</h3>
  <div class="container message">
    {{landingMessage}}
  </div>
</section>

<div class="container blog_right_sidebar">
  <app-recent></app-recent>
</div>
