<section class="next this-page-1">
    <h3>About</h3>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <img class="img-fluid" src="{{profilePicture}}" alt="">
            </div>
            <div class="col-lg-5 col-md-12 offset-md-0 offset-lg-1">
                <div class="about-content">
                    <h4 style="max-width: 70%">
                        {{aboutHeading}}
                    </h4>
                    <p>{{about}}</p>
                    <br>
                    <a class="btn btn-primary" style="font-family: Arial, Helvetica, sans-serif;"
                        href="{{cvUrlPrefix + '/' + settings.siteSettings.curriculumVitae}}/cv.pdf">Download Cv</a>
                </div>
            </div>
        </div>
    </div>
</section>