<section class="next this-page-1">
  <h3>{{pageTitle}}</h3>
  <div class="container">

    <div class="row">

      <div *ngFor="let exhibition of groupOne" class="col-md-4">
        <div class="card mb-2">
          <a routerLink="/exhibitions/view/{{exhibition._id}}">
            <img class="card-img-top shadow" src="{{backend.uploadsUrlPrefix}}/small/{{exhibition.imageSortHash}}.jpg"
              alt="{{exhibition.title}}"></a>
          <div class="card-body">
            <h4 class="card-title"><a routerLink="/exhibitions/view/{{exhibition._id}}">{{exhibition.title}}</a></h4>
            <p class="card-text">{{exhibition.desc}}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div *ngFor="let exhibition of groupTwo" class="col-md-4">
        <div class="card mb-2">
          <a routerLink="/exhibitions/view/{{exhibition._id}}">
            <img class="card-img-top shadow" src="{{backend.uploadsUrlPrefix}}/small/{{exhibition.imageSortHash}}.jpg"
              alt="{{exhibition.title}}"></a>
          <div class="card-body">
            <h4 class="card-title"><a routerLink="/exhibitions/view/{{exhibition._id}}">{{exhibition.title}}</a></h4>
            <p class="card-text">{{exhibition.desc}}</p>
          </div>
        </div>
      </div>

    </div>

    <app-pagination [pageUrl]="backendUrl" (pagedData)="receivePageContent($event)"></app-pagination>

  </div>
</section>