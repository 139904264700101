<!-- Bootstrap alerts -->
<div class="alert alert-success text-center" role="alert" (click)="resetAlert()"
     *ngIf="operationSuccessful">
    <a class="close" aria-label="close">&times;</a>
    <b>Operation successfully! </b>
</div>
<div class="alert alert-danger text-center" role="alert" (click)="resetAlert()"
     *ngIf="operationFailed">
    <a class="close" aria-label="close">&times;</a>
    Operation was <b>unsuccessful</b>!
</div>

<section class="next this-page-1">
    <div class="container b">
        <h3>Landing Page Images</h3>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12">
                    <h5 class="title">Logo</h5>
                    <div class="mb-3 mt-1" style="min-height: 50px;">
                        <img *ngIf="logoPreviewUrl" style="margin-top: 1rem" [src]="logoPreviewUrl"
                             class="img-fluid" />
                    </div>
                    <b>Choose file</b>
                    <p><small>A PNG image, whose dimension MUST be 154x32 to fit. Please ensure it's
                            154x32</small></p>
                    <div class="form-group">
                        <input type="file" name="image" (change)="logoFileProgress($event)" />
                        <button (click)="uploadSiteLogo()" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span *ngIf="disableSubmitButton && _logoPointer; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </form>

        <form [formGroup]="form" (ngSubmit)="saveSettings()">

            <div class="row">
                <div class="col-12">
                    <h5 class="title">First Landing Image</h5>
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="previewUrl[0]" [src]="previewUrl[0]" class="img-fluid"
                             height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>The preferred image ratio is classic 2:1. For example, a 1344x678
                            photo</small></p>
                    <p><small>Please keep the originals of your photos, as photos uploaded here are
                            subject to data
                            compression</small></p>
                    <div class="form-group">
                        <input type="file" name="image" (change)="fileProgress($event, 0)" />
                        <button (click)="uploadLandingImage(0)" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _pointer === 0; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12">
                    <h5 class="title">Second Landing Image</h5>
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="previewUrl[1]" [src]="previewUrl[1]" class="img-fluid"
                             height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>The preferred image ratio is classic 2:1. For example, a 1344x678
                            photo</small></p>
                    <p><small>Please keep the originals of your photos, as photos uploaded here are
                            subject to data
                            compression</small></p>
                    <div class="form-group">
                        <input type="file" name="image" (change)="fileProgress($event, 1)" />
                        <button (click)="uploadLandingImage(1)" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _pointer === 1; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12">
                    <h5 class="title">Third Landing Image</h5>
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="previewUrl[2]" [src]="previewUrl[2]" class="img-fluid"
                             height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>The preferred image ratio is classic 2:1. For example, a 1344x678
                            photo</small></p>
                    <p><small>Please keep the originals of your photos, as photos uploaded here are
                            subject to data
                            compression</small></p>
                    <div class="form-group">
                        <input type="file" name="image" (change)="fileProgress($event, 2)" />
                        <button (click)="uploadLandingImage(2)" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _pointer === 2; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12">
                    <h5 class="title">Fourth Landing Image</h5>
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="previewUrl[3]" [src]="previewUrl[3]" class="img-fluid"
                             height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>The preferred image ratio is classic 2:1. For example, a 1344x678
                            photo</small></p>
                    <p><small>Please keep the originals of your photos, as photos uploaded here are
                            subject to data
                            compression</small></p>
                    <div class="form-group">
                        <input type="file" name="image" (change)="fileProgress($event, 3)" />
                        <button (click)="uploadLandingImage(3)" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _pointer === 3; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12">
                    <h5 class="title">Fifth Landing Image</h5>
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="previewUrl[4]" [src]="previewUrl[4]" class="img-fluid"
                             height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>The preferred image ratio is classic 2:1. For example, a 1344x678
                            photo</small></p>
                    <p><small>Please keep the originals of your photos, as photos uploaded here are
                            subject to data
                            compression</small></p>
                    <div class="form-group">
                        <input type="file" name="image" (change)="fileProgress($event, 4)" />
                        <button (click)="uploadLandingImage(4)" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _pointer === 4; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>

        </form>
    </div>
</section>

<section class="next this-page-1">
    <div class="container b">
        <h3>Bio</h3>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12">
                    <h5 class="title">Profile Photo</h5>
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="bioPreviewUrl.profilePicture"
                             [src]="bioPreviewUrl.profilePicture" class="img-fluid" height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>Your main profile photo which will be shown in About Me (recommend
                            image ratio is
                            2:3)</small></p>
                    <div class="form-group">
                        <input type="file" name="profile"
                               (change)="bioFileProgress($event, 'profilePicture')" />
                        <button (click)="uploadProfilePicture('profilePicture')"
                                class="btn btn-primary" [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _bioPointer == 'profilePicture'; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12">
                    <h5 class="title">Thumbnail Photo</h5>
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="bioPreviewUrl.thumbnail" [src]="bioPreviewUrl.thumbnail"
                             class="img-fluid" height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>A small square photo showing only your face</small></p>
                    <div class="form-group">
                        <input type="file" name="thumbnail"
                               (change)="bioFileProgress($event, 'thumbnail')" />
                        <button (click)="uploadProfilePicture('thumbnail')" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _bioPointer == 'thumbnail'; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12">
                    <h5 class="title">CV Document</h5>
                    <b>Choose file</b>
                    <p><small>Must be in PDF format</small></p>
                    <div class="form-group">
                        <input type="file" name="cv" (change)="setCV($event, 'curriculumVitae')" />
                        <button (click)="uploadCV()" class="btn btn-primary"
                                [disabled]="disableSubmitButton">
                            <span
                                  *ngIf="disableSubmitButton && _bioPointer == 'curriculumVitae'; else showUploadText">
                                <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Uploading...
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button style="margin-top: 10px" class="btn btn-primary" (click)="scrollNow()"
                            [disabled]="disableSubmitButton">
                        <span *ngIf="!disableSubmitButton">Save</span>
                        <span *ngIf="disableSubmitButton">
                            <span class="spinner-border spinner-border-sm" role="status"
                                  aria-hidden="true"></span>
                            Uploading...
                        </span>
                    </button>
                    <app-scroll-to-top></app-scroll-to-top>
                </div>
            </div>
        </form>
    </div>
</section>

<!-- ngIf else statement to show 'Upload' text -->
<ng-template #showUploadText>Upload</ng-template>
