<!-- <footer> -->
<ul class="bg-light">
    <a href="{{facebook}}">
        <li class="fa icon-large fa-facebook"></li>
    </a>
    <a href="{{twitter}}">
        <li class="fa icon-large fa-twitter"></li>
    </a>
    <a href="{{youtube}}">
        <li class="fa icon-large fa-youtube"></li>
    </a>
    <a href="{{instagram}}">
        <li class="fa icon-large fa-instagram"></li>
    </a>
</ul>
<!-- </footer> -->