<!-- Bootstrap alerts -->
<div class="alert alert-success text-center" role="alert" (click)="resetAlert()" *ngIf="successCreatingWork">
    <a class="close" aria-label="close">&times;</a>
    <b>{{successMsg}}</b><a class="alert-link" routerLink="{{link}}">View</a>
</div>
<div class="alert alert-danger text-center" role="alert" (click)="resetAlert()" *ngIf="failedCreatingWork">
    <a class="close" aria-label="close">&times;</a>
    {{failureMsg}}
</div>
<div class="alert alert-danger text-center" role="alert" *ngIf="displayNoCategoriesMessage">
    You haven't created any categories yet, so creating a work will fail.
    Go to settings, and add a work category first!
</div>

<section class="next this-page-1">
    <h3 class="title">{{saveWorkText}}</h3>
    <div class="container b">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3" style="min-height: 200px">
                        <img *ngIf="previewUrl" [src]="previewUrl" class="img-fluid" height="200" />
                    </div>
                    <b>Choose file</b>
                    <p><small>Please keep the originals of your photos, as photos uploaded here are subject to data
                            compression</small></p>
                    <div class="form-group">
                        <input type="file" name="image" (change)="fileProgress($event)" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <input class="form-control" formControlName="title" name="title" id="title" type="text"
                            placeholder="Title...">
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <textarea class="form-control w-100" formControlName="desc" name="desc" id="desc" cols="30"
                            rows="9" placeholder="Some description..."></textarea>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" formControlName="featured" name="featured" id="featured"
                            type="checkbox">
                        <label class="form-check-label" for="featured">Add to Featured List</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="category">Category</label>
                        <select class="form-control" formControlName="category" name="category" id="category">
                            <option *ngFor="let category of categories; i as index" label="{{category.title}}"
                                value="{{category._id}}">{{category.title}}</option>
                        </select>

                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <button style="margin-top: 10px" class="btn btn-primary" [disabled]="disableSubmitButton">
                            <span *ngIf="!disableSubmitButton">{{saveWorkText}}</span>
                            <span *ngIf="disableSubmitButton">
                                <span class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>{{saveWorkProgressText}}
                            </span>
                        </button>
                    </div>
                </div>
                <app-scroll-to-top></app-scroll-to-top>
            </div>
        </form>
    </div>
</section>