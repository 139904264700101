<section class="contact-section area-padding">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <h2 class="contact-title">Change Password</h2>
            </div>
            <div class="col-lg-8">
                <div class="alert alert-danger text-center" role="alert" (click)="resetAlert()" style="margin-left: 0" *ngIf="showFailMessage">
                    <a class="close" aria-label="close">&times;</a>
                    <b>Password change failed. Please ensure you typed the current password correctly, and retyped the new
                        passwords twice, correctly as well.</b>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="alert alert-success text-center" role="alert" (click)="resetAlert()" style="margin-left: 0" *ngIf="showSuccessMessage">
                    <a class="close" aria-label="close">&times;</a>
                    <b>Password change successfully.</b>
                </div>
            </div>
            <div class="col-lg-8">
                <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="form-contact contact_form"
                    id="changePasswordForm">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <input class="form-control" formControlName="oldPassword" name="old" id="old"
                                    type="password" placeholder="Enter current password">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" formControlName="newPassword" name="new" id="new"
                                    type="password" placeholder="Enter new password">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" formControlName="retypePassword" name="retype" id="retype"
                                    type="password" placeholder="Retype new password">
                            </div>
                        </div>
                        <!-- TODO CAPTCHA verification -->
                        <!-- <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" name="captcha" id="captcha" type="captcha"
                                    placeholder="Enter verification captcha">
                            </div>
                        </div> -->
                    </div>
                    <div class="form-group mt-3">
                        <button class="btn btn-primary" type="submit" [disabled]="disableSubmitButton">
                            <span *ngIf="!disableSubmitButton">Change Password</span>
                            <span *ngIf="disableSubmitButton">
                                <span class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>Changing password...
                            </span>
                        </button>
                    </div>
                    <app-scroll-to-top></app-scroll-to-top>
                </form>
            </div>
        </div>
    </div>
</section>