<section class="contact-section area-padding">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <h2 class="contact-title">Login</h2>
            </div>
            <div class="col-lg-8">
                <div class="alert alert-danger text-center" role="alert" (click)="resetAlert()" style="margin-left: 0" *ngIf="showFailMessage">
                    <a class="close" aria-label="close">&times;</a>
                    <b>Login failed. Incorrect e-mail and/or password.</b>
                </div>
            </div>
            <div class="col-lg-8">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-contact contact_form" id="loginForm">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <input class="form-control" formControlName="email" name="email" id="email" type="email"
                                    placeholder="Enter email address">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" formControlName="password" name="password" id="password" type="password"
                                    placeholder="Enter password">
                            </div>
                        </div>
                        <!-- TODO CAPTCHA verification -->
                        <!-- <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" name="captcha" id="captcha" type="captcha"
                                    placeholder="Enter verification captcha">
                            </div>
                        </div> -->
                    </div>
                    <div class="form-group mt-3">
                        <button class="btn btn-primary" type="submit" [disabled]="disableSubmitButton">
                            <span *ngIf="!disableSubmitButton">Login</span>
                            <span *ngIf="disableSubmitButton">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Logging in...
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>