<nav class="blog-pagination justify-content-center d-flex">
    <ul class="pagination">

        <!-- If current page number is greater than 1 -->
        <!-- Show << link to go back to page 1 -->
        <li *ngIf="pageInformation.currentPageNumber > 1" class="page-item">
            <a routerLink="/{{actionUrl}}/p/1" class="page-link" aria-label="First">
                <i class="fa fa-angle-double-left"></i>
            </a>
        </li>
        <!-- Show < link to go to previous page -->
        <li *ngIf="pageInformation.currentPageNumber > 1" class="page-item">
            <a routerLink="/{{actionUrl}}/p/{{pageInformation.currentPageNumber - 1}}" class="page-link" aria-label="Previous">
                <i class="fa fa-angle-left"></i>
            </a>
        </li>

        <!-- Build numeric page links using loop -->
        <span *ngFor="let x of numericLinks">
            <!-- Ensure we start from a valid page number, since numericLinks can contain negative numbers -->
            <li *ngIf="x === pageInformation.currentPageNumber" class="page-item active">
                <a class="page-link">{{pageInformation.currentPageNumber}}</a>
            </li>
            <li *ngIf="x !== pageInformation.currentPageNumber" class="page-item">
                <a routerLink="/{{actionUrl}}/p/{{x}}" class="page-link">{{x}}</a>
            </li>
        </span>

        <!-- If not on the last page show > link to go to next page -->
        <li *ngIf="pageInformation.currentPageNumber !== pageInformation.totalNumberOfPages" class="page-item">
            <a routerLink="/{{actionUrl}}/p/{{pageInformation.currentPageNumber + 1}}" class="page-link" aria-label="Next">
                <i class="fa fa-angle-right"></i>
            </a>
        </li>

        <!-- If the total number of pages is greater than 10, show a link to move halfway through the pages -->
        <li *ngIf="pageInformation.totalNumberOfPages >= 10" class="page-item">
            <a routerLink="/{{actionUrl}}/p/{{halfWayThroughThePages}}" class="page-link" aria-label="Halfway">
                <i class="fa fa-divide"></i>
            </a>
        </li>

        <!-- If not on last page, show link to last page -->
        <li *ngIf="pageInformation.currentPageNumber !== pageInformation.totalNumberOfPages" class="page-item">
            <a routerLink="/{{actionUrl}}/p/{{pageInformation.totalNumberOfPages}}" class="page-link" aria-label="Last">
                <i class="fa fa-angle-double-right"></i>
            </a>
        </li>
    </ul>
</nav>

<!-- Reference -->
<!-- <nav class="blog-pagination justify-content-center d-flex">
    <ul class="pagination">
        <li class="page-item">
            <a routerLink="/blog" class="page-link" aria-label="Previous">
                <i class="fa fa-angle-left"></i>
            </a>
        </li>
        <li class="page-item">
            <a routerLink="/blog" class="page-link">1</a>
        </li>
        <li class="page-item active">
            <a routerLink="/blog" class="page-link">2</a>
        </li>
        <li class="page-item">
            <a routerLink="/blog" class="page-link" aria-label="Next">
                <i class="fa fa-angle-right"></i>
            </a>
        </li>
    </ul>
</nav> -->