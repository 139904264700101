<section class="container next this-page-1">
    <h3>{{exhibition?.title}}</h3>
    <p class="card-text">{{exhibition?.desc}}</p>
    <div class="card mb-2">
        <img class="card-img-top shadow"
             src="{{backend.uploadsUrlPrefix}}/big/{{exhibition?.imageSortHash}}.jpg"
             alt="{{exhibition?.title}}">

        <div class="card-body" style="position: relative;">
            <ul *ngIf="canEdit()" id="actions">
                <a (click)="editExhibition()" class="delete">
                    <li class="fa icon-large fa-edit"></li>
                    <span style="color: rgba(0, 0, 0, 0.7)">Edit</span>
                </a>
                <a (click)="deleteExhibition()" class="delete">
                    <li class="fa icon-large fa-trash"></li>
                    <span style="color: rgba(0, 0, 0, 0.7)">Delete</span>
                </a>
            </ul>
        </div>

        <app-youtube [url]="youtubeUrl"></app-youtube>

    </div>
</section>
