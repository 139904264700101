<section class="next this-page-1">
    <h3>Blogs</h3>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mb-5 mb-lg-0">
                <div class="">

                    <article *ngFor="let blog of blogs" class="blog_item">
                        <div class="blog_item_img">
                            <a routerLink="/blog/view/{{blog._id}}"><img class="card-img rounded-0"
                                    src="{{backend.uploadsUrlPrefix}}/big/{{blog.imageSortHash}}.jpg" alt=""></a>
                        </div>

                        <div class="blog_details">
                            <a class="d-inline-block" routerLink="/blog/view/{{blog._id}}">
                                <h2>{{blog.title}}</h2>
                            </a>
                            <p>{{blog.desc}}</p>
                            <ul class="blog-info-link">
                                <li><a><i class="fa fa-user"></i>{{blog.authorEmail}}</a></li>
                                <li><a><i class="fa fa-comments"></i>{{blog.commentCount !== 0 ? blog.commentCount + ' comments' : 'No Comments'}}</a></li>
                                <li><a>
                                        <i class="fa fa-comments"></i>
                                        {{ stringAsDate(blog.createdOn) | date: 'MMM d y, h:MM a' }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </article>

                    <app-pagination [pageUrl]="backendUrl" (pagedData)="receivePageContent($event)">
                    </app-pagination>

                </div>
            </div>

            <div class="col-lg-4">
                <app-side-bar></app-side-bar>
            </div>

        </div>
    </div>
</section>