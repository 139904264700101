<div class="alert alert-success text-center" role="alert" (click)="resetAlert()" *ngIf="successSavingSettings">
    <a class="close" aria-label="close">&times;</a>
    <b>Settings saved successfully! </b>
</div>
<div class="alert alert-danger text-center" role="alert" (click)="resetAlert()" *ngIf="failedSavingSettings">
    <a class="close" aria-label="close">&times;</a>
    A <b>problem</b> while creating saving your settings, and it wasn't saved successfully!
</div>

<section class="contact-section">
    <div class="container">
        <div class="row" style="margin-top: 1em;">
            <div class="col-3">
                <button type="submit" (click)="gotoUploadDetails()" class="btn btn-primary">Upload Files</button>
            </div>
            <div class="col-3">
                <button type="submit" (click)="gotoNewsLetters()" class="btn btn-primary">Manage Newsletters</button>
            </div>
            <div class="col-3">
                <button type="submit" (click)="gotoWorkCategories()" class="btn btn-primary">Work Categories</button>
            </div>
            <div class="col-3">
                <button type="submit" (click)="gotoChangePassword()" class="btn btn-primary">Change Password</button>
            </div>
            <div class="col-12" style="margin: 1rem 0">
                Tip: Do not login with another person's device. If you do, remember to logout or that person will have
                access!
                You can change your password, to invalidate any logins done with other devices.
            </div>
        </div>
        <br>
        <div class="row">
            <br>
            <div class="col-12">
                <h2 class="contact-title">Settings</h2>
            </div>
            <div class="col-12">
                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-contact contact_form" id="contactForm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                Name
                                <input formControlName="name" class="form-control" name="name" id="name" type="text"
                                    maxlength="20">
                            </div>
                        </div>
                        <!-- <div class="col-12">
                            <div class="form-group">
                                Occupation
                                <input formControlName="occupation" class="form-control" name="occupation"
                                    id="occupation" type="text" maxlength="20">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Short description
                                <input formControlName="desc" class="form-control" name="desc" id="desc" type="text"
                                    maxlength="95">
                            </div>
                        </div> -->
                        <div class="col-12">
                            <div class="form-group">
                                Landing Message Heading
                                <input formControlName="landingMessageHeading" class="form-control" name="landingMessageHeading" id="landingMessageHeading" type="text"
                                    maxlength="160">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Landing Message
                                <textarea formControlName="landingMessage" class="form-control w-100" name="about" id="about"
                                    cols="30" rows="9" maxlength="1500"></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                About Me Heading
                                <input formControlName="aboutHeading" class="form-control" name="aboutHeading"
                                    id="aboutHeading" type="text" maxlength="500">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                About Me
                                <textarea formControlName="about" class="form-control w-100" name="about" id="about"
                                    cols="30" rows="9" maxlength="100000"></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Phone Number
                                <input formControlName="phone" class="form-control" name="phone" id="phone" type="text"
                                    maxlength="16">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Email Address
                                <input formControlName="email" class="form-control" name="email" id="email" type="text"
                                    maxlength="55">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Facebook Link
                                <input formControlName="facebook" class="form-control" name="facebook" id="facebook"
                                    type="text" maxlength="55">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Twitter Link
                                <input formControlName="twitter" class="form-control" name="twitter" id="twitter"
                                    type="text" maxlength="55">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Youtube Link
                                <input formControlName="youtube" class="form-control" name="youtube" id="youtube"
                                    type="text" maxlength="55">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Instagram Link
                                <input formControlName="instagram" class="form-control" name="instagram" id="instagram"
                                    type="text" maxlength="55">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                City
                                <input formControlName="city" class="form-control" name="city" id="city" type="text"
                                    maxlength="16">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                District
                                <input formControlName="district" class="form-control" name="district" id="district"
                                    type="text" maxlength="16">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Country
                                <input formControlName="country" class="form-control" name="country" id="country"
                                    type="text" maxlength="16">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                Opening Times
                                <input formControlName="openingTimes" class="form-control" name="openingTimes"
                                    id="openingTimes" type="text" maxlength="55">
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                    <app-scroll-to-top></app-scroll-to-top>
                </form>


            </div>
        </div>
    </div>
</section>