<div class="blog_right_sidebar">

    <app-search></app-search>

    <app-recent></app-recent>

    <aside class="single_sidebar_widget newsletter_widget">
        <h4 class="widget_title">Newsletter</h4>

        <form [formGroup]="newsletterForm" (ngSubmit)="addContact()">
            <div class="form-group">
                <input type="email" formControlName="email" class="form-control" placeholder="Enter email" required>
            </div>
            <button class="btn btn-primary" type="submit" [disabled]="disableSubmitButtonForNewsletterForm">
                <span *ngIf="!disableSubmitButtonForNewsletterForm">Subscribe</span>
                <span *ngIf="disableSubmitButtonForNewsletterForm">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Subscribing...
                </span>
            </button>
        </form>
    </aside>
</div>