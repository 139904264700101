<section class="contact-section area-padding">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <h2 class="contact-title">Send me a message</h2>
            </div>
            <div class="col-lg-8">
                <form class="form-contact contact_form" action="contact_process.php" method="post" id="contactForm">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input class="form-control" name="name" id="name" type="text"
                                    placeholder="Enter your name">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input class="form-control" name="email" id="email" type="email"
                                    placeholder="Enter email address">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" name="subject" id="subject" type="text"
                                    placeholder="Enter Subject">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9"
                                    placeholder="Enter Message"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <button type="submit" class="btn btn-primary">Send Message</button>
                    </div>
                </form>


            </div>

            <div class="col-lg-4">
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="fa fa-home"></i></span>
                    <div class="media-body">
                        <h3>{{city}}, {{country}}</h3>
                        <p>{{district}}</p>
                    </div>
                </div>
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="fa fa-tablet"></i></span>
                    <div class="media-body">
                        <h3><a href="tel:{{phone}}">{{phone}}</a></h3>
                        <p>{{openingTimes}}</p>
                    </div>
                </div>
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="fa fa-envelope"></i></span>
                    <div class="media-body">
                        <h3><a href="mailto:{{email}}">{{email}}</a></h3>
                        <p>Send me a message any time!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>